import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface SipChartProps {
  data: any[];
}

const numberFormatter = (value: number) => new Intl.NumberFormat('en-IN').format(value);

const SipChart: React.FC<SipChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data} margin={{ top: 20, right: 30, left: 80, bottom: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" tickFormatter={numberFormatter} />
        <YAxis tickFormatter={numberFormatter} />
        <Tooltip formatter={(value: number) => numberFormatter(value)} />
        <Legend />
        <Line type="monotone" dataKey="savings" stroke="#8884d8" name="Total Savings (₹)" />
        <Line type="monotone" dataKey="expenses" stroke="#82ca9d" name="Yearly Expenses (₹)" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SipChart;
