import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Typography, Box, CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';
import ChartDisplay from './ChartDisplay';
import TotalsTable from './TotalsTable';
import Controls from './Controls';
import AveragesTable from './AveragesTable';
import {
  Transaction,
  ChartDataset,
  VisibleLines,
  ViewType,
  GraphType,
  OverallTotals,
  Averages,
} from '../types/FileUpload';
import Loading from './Loading';
import { calculateMonthsDifference } from '../helper/dateHelpers';

const fetchTransactions = async (startDate: string, endDate: string) => {
  const token = localStorage.getItem('token');
  const response = await axios.get<Transaction[]>(`${process.env.REACT_APP_API_URL}/transactions`, {
    params: { startDate, endDate },
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const fetchFlaggedSummary = async (startDate: string, endDate: string) => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/transactions/flagged-summary`, {
    params: { startDate, endDate }, // Pass startDate and endDate as params
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const Dashboard: React.FC = () => {
  const currentDate = new Date();
  const pastDate = new Date();
  pastDate.setFullYear(2024, 4, 1); // Year: 2024, Month: 4 (May), Day: 1

  const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  const formattedPastDate = pastDate.toISOString().split('T')[0];

  const [startDate, setStartDate] = useState<string>(formattedPastDate);
  const [endDate, setEndDate] = useState<string>(formattedCurrentDate);
  const [months, setMonths] = useState<number>(calculateMonthsDifference(new Date(endDate), new Date(startDate)));

  const [view, setView] = useState<ViewType>('monthly');
  const [visibleLines, setVisibleLines] = useState<VisibleLines>({
    credit: true,
    debit: true,
    balance: false,
    averageCredit: false,
    averageDebit: false,
  });
  const [graphType, setGraphType] = useState<GraphType>('line');
  const [chartData, setChartData] = useState<{ labels: string[]; datasets: ChartDataset[] } | null>(null);
  const [totals, setTotals] = useState<any[]>([]);
  const [overallTotals, setOverallTotals] = useState<OverallTotals>({
    totalCredit: '',
    totalDebit: '',
    remainingBalance: '',
    transferredAmount: '',
    creditedAmount: '',
    netDebit: ''
  });
  const [averages, setAverages] = useState<Averages>({
    averageBalance: '',
    averageCredit: '',
    averageDebit: '',
  });
  const [netDebit, setNetDebit] = useState(0)
  const [flaggedSummary, setFlaggedSummary] = useState({ amountNotFlagged: 0, amountFlagged: 0 });

  useEffect(() => {
    // Recalculate the number of months when the startDate or endDate changes
    setMonths(calculateMonthsDifference(new Date(endDate), new Date(startDate)));
  }, [startDate, endDate]);

  const { data: transactions, isLoading, error } = useQuery(
    ['transactions', startDate, endDate],
    () => fetchTransactions(startDate, endDate),
    {
      onSuccess: (data) => {
        processChartData(data, view, visibleLines);
      },
    }
  );

  const { data: flaggedSummaryData, isLoading: flaggedLoading } = useQuery(
    ['flaggedSummary', startDate, endDate],
    () => fetchFlaggedSummary(startDate, endDate), // Pass startDate and endDate to flagged summary fetch
    {
      onSuccess: (data) => {
        setFlaggedSummary(data);
      },
    }
  );

  // Ensure both transactions and flaggedSummary are loaded before processing
useEffect(() => {
  if (!isLoading && !flaggedLoading && transactions && flaggedSummaryData) {
    processChartData(transactions, view, visibleLines);
  }
}, [transactions, flaggedSummaryData, isLoading, flaggedLoading, view, visibleLines]);

  const handleViewChange = (newView: ViewType) => {
    setView(newView);
    if (transactions && transactions.length > 0) {
      processChartData(transactions, newView, visibleLines);
    }
  };

  const handleGraphTypeChange = (newGraphType: GraphType) => {
    setGraphType(newGraphType);
  };

  const handleLineVisibilityChange = (name: keyof VisibleLines, checked: boolean) => {
    const newVisibleLines = { ...visibleLines, [name]: checked };
    setVisibleLines(newVisibleLines);
    if (transactions && transactions.length > 0) {
      processChartData(transactions, view, newVisibleLines);
    }
  };

  const handleDateChange = (key: 'startDate' | 'endDate', value: string) => {
    if (key === 'startDate') {
      setStartDate(value);
    } else if (key === 'endDate') {
      setEndDate(value);
    }
  };

  const processChartData = (data: Transaction[], view: ViewType, visibleLines: VisibleLines) => {
    const periods: Record<
      string,
      { credit: number; debit: number; balance: number; lastBalanceDate: Date | null }
    > = {};
  
    data.forEach((row) => {
      try {
        const date = new Date(row.date);
  
        let periodKey: string;
        if (view === 'daily') {
          periodKey = date.toISOString().split('T')[0];
        } else if (view === 'monthly') {
          periodKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
        } else if (view === 'yearly') {
          periodKey = date.getFullYear().toString();
        } else {
          throw new Error(`Unexpected view: ${view}`);
        }
  
        if (!periods[periodKey]) {
          periods[periodKey] = { credit: 0, debit: 0, balance: 0, lastBalanceDate: null };
        }
  
        const amount = parseFloat(row.amount.replace(/,/g, '') || '0');
        const balance = parseFloat(row.balance.replace(/,/g, '') || '0');
  
        if (row.type === 'CR') {
          periods[periodKey].credit += amount;
        } else if (row.type === 'DR') {
          periods[periodKey].debit += amount;
        }
  
        if (!periods[periodKey].lastBalanceDate || date > periods[periodKey].lastBalanceDate!) {
          periods[periodKey].balance = balance;
          periods[periodKey].lastBalanceDate = date;
        }
      } catch (error) {
        console.error('Error processing row:', row, error);
      }
    });
  
    const sortedPeriods = Object.keys(periods)
      .filter((period) => !isNaN(new Date(period).getTime()))
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  
    const labels = sortedPeriods.map((period) => {
      if (view === 'monthly') {
        const [year, month] = period.split('-');
        return new Date(Number(year), Number(month) - 1).toLocaleString('default', {
          month: 'short',
          year: 'numeric',
        });
      } else if (view === 'daily') {
        return new Date(period).toLocaleDateString('default', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
      } else {
        return period;
      }
    });
  
    const credits = sortedPeriods.map((label) => periods[label].credit);
    const debits = sortedPeriods.map((label) => periods[label].debit);
    const balances = sortedPeriods.map((label) => periods[label].balance);
    
    const totalCredit = credits.reduce((acc, credit) => acc + credit, 0).toFixed(2);
    const totalDebit = debits.reduce((acc, debit) => acc + debit, 0).toFixed(2);
    const remainingBalance = balances.length > 0 ? balances[balances.length - 1].toString() : '0';
    
    // Ensure flaggedSummary data is available before using it
    const transferredAmount = (flaggedSummary.amountFlagged ?? 0).toFixed(2);
    const creditedAmount = flaggedSummary.amountNotFlagged.toFixed(2);
    const netDebit = (parseFloat(totalDebit) - flaggedSummary.amountNotFlagged).toFixed(2);
    setNetDebit(Number(netDebit))
    
    // Calculate average based on netDebit instead of totalDebit
    const averageDebit = (parseFloat(netDebit) / debits.length).toFixed(2);
    
    setOverallTotals({
      totalCredit,
      totalDebit,
      remainingBalance,
      transferredAmount,
      creditedAmount,
      netDebit,
    });
    
    const averageBalance = (
      balances.reduce((acc, balance) => acc + balance, 0) / balances.length
    ).toFixed(2);
    const averageCredit = (
      credits.reduce((acc, credit) => acc + credit, 0) / credits.length
    ).toFixed(2);

    // Adjust for netDebit in averageDebits
    const averageCredits = new Array(credits.length).fill(parseFloat(averageCredit));
    const averageDebits = new Array(debits.length).fill(parseFloat(averageDebit));
    
    setAverages({
      averageBalance,
      averageCredit,
      averageDebit,
    });


    const datasets: ChartDataset[] = [];
    if (visibleLines.credit) {
      datasets.push({
        label: 'Credit',
        data: credits,
        borderColor: 'green',
        backgroundColor: 'rgba(75, 192, 192)',
        fill: true,
      });
    }
    if (visibleLines.debit) {
      datasets.push({
        label: 'Debit',
        data: debits,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 99, 132)',
        fill: true,
      });
    }
    if (visibleLines.balance) {
      datasets.push({
        label: 'Balance',
        data: balances,
        borderColor: 'blue',
        backgroundColor: 'rgba(54, 162, 235)',
        fill: true,
      });
    }
    if (visibleLines.averageCredit) {
      datasets.push({
        label: 'Average Credit',
        data: averageCredits,
        borderColor: 'purple',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        fill: false,
        borderDash: [5, 5],
      });
    }
    if (visibleLines.averageDebit) {
      datasets.push({
        label: 'Average Debit',
        data: averageDebits,
        borderColor: 'orange',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        fill: false,
        borderDash: [5, 5],
      });
    }

    setChartData({
      labels,
      datasets,
    });

    const newTotals = sortedPeriods.map((label, index) => ({
      period: labels[index],
      credit: periods[label].credit,
      debit: periods[label].debit,
      balance: periods[label].balance,
    }));
    setTotals(newTotals);
  };

  if (isLoading || flaggedLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 1200, mx: 'auto', py: 4 }}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          Error fetching transactions.
        </Typography>
      ) : (
        <>
          <Card sx={{ mb: 3, p: 3 }}>
            <Typography variant="h4" component="h2">
              Dashboard
            </Typography>
            {transactions?.length === 0 ? (
              <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                No transactions found. It looks like you don't have any transactions in your account.
              </Typography>
            ) : (
              <>
                <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                  Select a date range and view your transactions
                </Typography>
                <Controls
                  view={view}
                  onChangeView={handleViewChange}
                  graphType={graphType}
                  onChangeGraphType={handleGraphTypeChange}
                  visibleLines={visibleLines}
                  onChangeLineVisibility={handleLineVisibilityChange}
                  startDate={startDate}
                  endDate={endDate}
                  onDateChange={handleDateChange}
                />
              </>
            )}
          </Card>

          {transactions && transactions.length > 0 && (
            <>
              {chartData && (
                <Card sx={{ mb: 3, p: 3 }}>
                  <ChartDisplay chartData={chartData} graphType={graphType} />
                </Card>
              )}
              <Card sx={{ mb: 3, p: 3 }}>
                <TotalsTable totals={totals} overallTotals={overallTotals} />
              </Card>
              <Card sx={{ p: 3 }}>
                <AveragesTable averages={averages} months={months} view={view} netDebit={netDebit}/>
              </Card>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Dashboard;
