import React from 'react';
import { Line, Bar, Doughnut, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { ChartDisplayProps } from '../types/ChartDisplay';
import { Card } from '@mui/material';

// Register necessary components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const ChartDisplay: React.FC<ChartDisplayProps> = ({ chartData, graphType }) => {
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return `${context.dataset.label}: ${new Intl.NumberFormat('en-IN', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(context.raw)}`;
          },
        },
      },
    },
  };

  return (
    <Card sx={{ padding: 2, marginY: 2, boxShadow: 3 }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {graphType === 'line' && <Line data={chartData} options={options} />}
        {graphType === 'bar' && <Bar data={chartData} options={options} />}
        {graphType === 'doughnut' && <Doughnut data={chartData} options={options} />}
        {graphType === 'pie' && <Pie data={chartData} options={options} />}
      </div>
    </Card>
  );
};

export default ChartDisplay;
