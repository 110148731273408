import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Homepage from './components/Homepage';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';  // Import RegisterPage
import Navbar from './components/Navbar';
import ProtectedRoute from './components/ProtectedRoute';
import { QueryClient, QueryClientProvider } from 'react-query';
import { setupAxiosInterceptors } from './utils/axiosInterceptor';  // Import the interceptor
import SipCalculator from './components/SipCalculator';

const App: React.FC = () => {
  const [username, setUsername] = useState<string | null>(null);

  const queryClient = new QueryClient();

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    setUsername(storedUsername);

    // Setup Axios Interceptors
    setupAxiosInterceptors();  // Initialize the interceptor
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setUsername(null);
  };

  const isAuthenticated = Boolean(localStorage.getItem('token'));

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Navbar username={username} onLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/sipcalculator" element={<SipCalculator />} />
          <Route path="/login" element={<LoginPage setUsername={setUsername} />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
