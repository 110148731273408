import React from 'react';
import { Grid, TextField } from '@mui/material';

interface InputFormProps {
  initialSavings: number;
  setInitialSavings: (value: number) => void;
  monthlySIP: number;
  setMonthlySIP: (value: number) => void;
  monthlyExpenses: number;
  setMonthlyExpenses: (value: number) => void;
  annualReturn: number;
  setAnnualReturn: (value: number) => void;
  inflationRate: number;
  setInflationRate: (value: number) => void;
  expenseGrowth: number;
  setExpenseGrowth: (value: number) => void;
  currentAge: number;
  setCurrentAge: (value: number) => void;
  retirementAge: number;
  setRetirementAge: (value: number) => void;
}

const InputForm: React.FC<InputFormProps> = ({
  initialSavings,
  setInitialSavings,
  monthlySIP,
  setMonthlySIP,
  monthlyExpenses,
  setMonthlyExpenses,
  annualReturn,
  setAnnualReturn,
  inflationRate,
  setInflationRate,
  expenseGrowth,
  setExpenseGrowth,
  currentAge,
  setCurrentAge,
  retirementAge,
  setRetirementAge,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          label="Current Age"
          variant="outlined"
          fullWidth
          value={currentAge}
          onChange={(e) => setCurrentAge(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Retirement Age"
          variant="outlined"
          fullWidth
          value={retirementAge}
          onChange={(e) => setRetirementAge(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Initial Savings (₹)"
          variant="outlined"
          fullWidth
          value={initialSavings}
          onChange={(e) => setInitialSavings(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Monthly SIP (₹)"
          variant="outlined"
          fullWidth
          value={monthlySIP}
          onChange={(e) => setMonthlySIP(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Monthly Expenses (₹)"
          variant="outlined"
          fullWidth
          value={monthlyExpenses}
          onChange={(e) => setMonthlyExpenses(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Annual Return (%)"
          variant="outlined"
          fullWidth
          value={annualReturn}
          onChange={(e) => setAnnualReturn(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Inflation Rate (%)"
          variant="outlined"
          fullWidth
          value={inflationRate}
          onChange={(e) => setInflationRate(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Expense Growth Rate (%)"
          variant="outlined"
          fullWidth
          value={expenseGrowth}
          onChange={(e) => setExpenseGrowth(Number(e.target.value))}
        />
      </Grid>
    </Grid>
  );
};

export default InputForm;
