export const calculateSIPDuration = (
  initialSavings: number,
  monthlySIP: number,
  monthlyExpenses: number,
  annualReturn: number,
  inflationRate: number,
  expenseGrowth: number,
  timeHorizon: number // Derived from current age and retirement age
) => {
  let totalSavings = initialSavings;
  let currentExpenses = monthlyExpenses * 12; // Start with yearly expenses for year 0
  const sipData = [];
  let years = 0;

  // Push the data for Year 0 (present situation)
  sipData.push({
    year: years, // Year 0
    savings: Math.round(totalSavings),
    expenses: Math.round(currentExpenses), // Current expenses (monthly * 12)
  });

  // Calculate for years 1 and onward
  while (years < timeHorizon) {
    years += 1;

    // Increase the total savings by the annual return, plus the annualized SIP contributions
    totalSavings = totalSavings * (1 + annualReturn / 100) + monthlySIP * 12;

    // Increase the expenses by both the expense growth rate and the inflation rate for the next year
    currentExpenses = currentExpenses * (1 + expenseGrowth / 100 + inflationRate / 100);

    sipData.push({
      year: years, // Starting from 1 now
      savings: Math.round(totalSavings),
      expenses: Math.round(currentExpenses), // Yearly expenses
    });
  }

  // Return both SIP data and the final savings and expenses
  return {
    sipData,
    finalSavings: totalSavings,
    finalExpenses: currentExpenses, // Yearly expenses at the end of SIP duration
  };
};



export const calculateDepletion = (
  finalSavings: number,
  finalExpenses: number,
  annualReturn: number,
  expenseGrowth: number,
  inflationRate: number // Add inflation rate to deplete calculation
) => {
  let totalSavings = finalSavings;
  let currentExpenses = finalExpenses; // Already annualized in the calculation
  const depletionData = [];
  let year = 0;
  let yearsAfterStable = 0;
  let stablePointReached = false;

  // Simulate depletion year by year until savings < yearly expenses or stable point reached
  while (totalSavings > 0 && (totalSavings > currentExpenses || !stablePointReached)) {
    const yearlyWithdrawal = currentExpenses; // Yearly expenses
    totalSavings = totalSavings * (1 + annualReturn / 100) - yearlyWithdrawal;

    depletionData.push({
      year: year, // Starting from year 0
      savings: Math.max(totalSavings, 0), // Ensure savings don't go negative
      expenses: Math.round(currentExpenses), // Yearly expenses
    });

    // If savings always exceed expenses and will not be crossed again
    if (!stablePointReached && totalSavings > currentExpenses) {
      stablePointReached = true;
    }

    // Continue plotting for 10 more years after reaching stability
    if (stablePointReached) {
      yearsAfterStable++;
      if (yearsAfterStable >= 80) {
        break; // Stop after 10 years once savings are confirmed stable
      }
    }

    // Increase expenses by the combined inflation rate and expense growth rate for the next year
    currentExpenses = currentExpenses * (1 + expenseGrowth / 100 + inflationRate / 100);
    year += 1;
  }

  return depletionData;
};
  