export const calculateMonthsDifference = (currentDate: Date, pastDate: Date): number => {
    const yearsDifference = currentDate.getFullYear() - pastDate.getFullYear();
    let monthsDifference = (yearsDifference * 12) + (currentDate.getMonth() - pastDate.getMonth());
  
    // Subtract only if the current day is less than the past day
    if (currentDate.getDate() < pastDate.getDate()) {
      monthsDifference -= 1;
    }
  
    return monthsDifference + 1; // Add 1 to include the full last month (if day >= start)
  };