import React from 'react';
import { TotalsTableProps } from '../types/TotalsTable';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';

const TotalsTable: React.FC<TotalsTableProps> = ({ totals, overallTotals  }) => {
  const formatNumber = (number: number | string): string => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(number));
  };

  return (
    <Card sx={{ padding: 2, marginTop: 2 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Total
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Period</TableCell>
            <TableCell>Gross Credit</TableCell>
            <TableCell>Gross Debit</TableCell>
            <TableCell>Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {totals.map((total, index) => (
            <TableRow key={index}>
              <TableCell>{total.period}</TableCell>
              <TableCell>{formatNumber(total.credit)}</TableCell>
              <TableCell>{formatNumber(total.debit)}</TableCell>
              <TableCell>{formatNumber(total.balance)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="h6" component="h3" sx={{ marginTop: 2 }}>
        Overall Totals
      </Typography>
      <Typography>Total Credit: {formatNumber(overallTotals.totalCredit)}</Typography>
      <Typography>Transferred Amount: {formatNumber(overallTotals.transferredAmount)}</Typography>
      <Typography>Total Debit: {formatNumber(overallTotals.totalDebit)}</Typography>
      <Typography>Credited (Refunded) Amount: {formatNumber(overallTotals.creditedAmount)}</Typography>
      <Typography>Net Debit: {formatNumber(overallTotals.netDebit)}</Typography>
      <Typography>Current Balance: {formatNumber(overallTotals.remainingBalance)}</Typography>
    </Card>
  );
};

export default TotalsTable;
