import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography, Box, Button } from '@mui/material';

const Homepage = () => {
  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Card sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Welcome to the Bank Statement Analyzer
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Easily analyze your bank statements with our powerful tools.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/dashboard">
          Go to Dashboard
        </Button>
      </Card>
    </Box>
  );
};

export default Homepage;
