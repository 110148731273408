import React from 'react';
import { AveragesTableProps } from '../types/AveragesTable';
import { Card, Typography, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material';
import { ViewType } from '../types/FileUpload';

const profitIcon = process.env.PUBLIC_URL + '/images/budget.png';

const AveragesTable: React.FC<AveragesTableProps & { months: number; view: ViewType; netDebit:number }> = ({ averages, months, view, netDebit }) => {
  const monthlyBudget = 100000; // Budget set to 1,00,000

  // Total budget for the calculated months
  const totalBudget = monthlyBudget * months;

  // Budget difference
  const budgetDifference = totalBudget - netDebit;

  const isOverBudget = budgetDifference < 0; // If the difference is negative, you're over budget

  return (
    <Card sx={{ padding: 2, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Averages
      </Typography>

      {/* Conditionally show Budget and Budget Difference when view is 'monthly' */}
      {view === 'monthly' && (
        <>
          <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
            <Typography variant="body1">
              <strong>Monthly Budget: ₹{monthlyBudget.toLocaleString('en-IN')}</strong>
            </Typography>
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left"><strong>Average Credit</strong></TableCell>
                <TableCell align="left"><strong>Average Debit</strong></TableCell>
                <TableCell align="left">
                  <strong>Budget Difference</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">{averages.averageCredit}</TableCell>
                <TableCell align="left">{averages.averageDebit}</TableCell>
                <TableCell 
                  align="left" 
                  sx={{ color: isOverBudget ? 'red' : 'green' }}> {/* Conditional color */}
                  <img 
                    src={profitIcon} 
                    alt="Financial Profit Icon" 
                    style={{ width: '20px', marginRight: '8px', verticalAlign: 'middle' }} 
                  />
                  {budgetDifference.toLocaleString('en-IN')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}

      {/* Show only average credit and debit when view is not 'monthly' */}
      {view !== 'monthly' && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left"><strong>Average Credit</strong></TableCell>
              <TableCell align="left"><strong>Average Debit</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">{averages.averageCredit}</TableCell>
              <TableCell align="left">{averages.averageDebit}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Card>
  );
};

export default AveragesTable;
