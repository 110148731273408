import React, { useState } from 'react';
import { Button, Grid, Box, Container, Typography } from '@mui/material';
import InputForm from './InputForm';
import SipChart from './SipChart';
import DepletionChart from './DepletionChart';
import { calculateDepletion, calculateSIPDuration } from '../helper/calculationHelpers';

const SipCalculator: React.FC = () => {
  const [initialSavings, setInitialSavings] = useState<number>(8000000);
  const [monthlySIP, setMonthlySIP] = useState<number>(200000);
  const [monthlyExpenses, setMonthlyExpenses] = useState<number>(100000);
  const [annualReturn, setAnnualReturn] = useState<number>(12);
  const [inflationRate, setInflationRate] = useState<number>(7);
  const [expenseGrowth, setExpenseGrowth] = useState<number>(5);
  const [currentAge, setCurrentAge] = useState<number>(32); // Default to 32
  const [retirementAge, setRetirementAge] = useState<number>(60); // Default to 60
  const [dataSIP, setDataSIP] = useState<any[]>([]);
  const [dataDepletion, setDataDepletion] = useState<any[]>([]);

  const handleCalculate = () => {
    const timeHorizon = retirementAge - currentAge; // Calculate time horizon from current and retirement age

    // Calculate SIP Duration and Savings Depletion
    const { sipData, finalSavings, finalExpenses } = calculateSIPDuration(
      initialSavings, monthlySIP, monthlyExpenses, annualReturn, inflationRate, expenseGrowth, timeHorizon
    );
    setDataSIP(sipData);

    const depletionData = calculateDepletion(finalSavings, finalExpenses, annualReturn, expenseGrowth,inflationRate);
    setDataDepletion(depletionData);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          SIP and Depletion Calculator
        </Typography>
        <InputForm
          initialSavings={initialSavings}
          setInitialSavings={setInitialSavings}
          monthlySIP={monthlySIP}
          setMonthlySIP={setMonthlySIP}
          monthlyExpenses={monthlyExpenses}
          setMonthlyExpenses={setMonthlyExpenses}
          annualReturn={annualReturn}
          setAnnualReturn={setAnnualReturn}
          inflationRate={inflationRate}
          setInflationRate={setInflationRate}
          expenseGrowth={expenseGrowth}
          setExpenseGrowth={setExpenseGrowth}
          currentAge={currentAge}
          setCurrentAge={setCurrentAge}
          retirementAge={retirementAge}
          setRetirementAge={setRetirementAge}
        />
        <Grid container justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleCalculate}>
            Calculate SIP and Depletion
          </Button>
        </Grid>

        {dataSIP.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <SipChart data={dataSIP} />
          </Box>
        )}

        {dataDepletion.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <DepletionChart data={dataDepletion} />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default SipCalculator;
