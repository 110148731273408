import React from 'react';
import {
  Card,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ControlsProps } from '../types/Controls';
import { GraphType, ViewType } from '../types/FileUpload';
import dayjs, { Dayjs } from 'dayjs';

const Controls: React.FC<ControlsProps> = ({
  view,
  onChangeView,
  graphType,
  onChangeGraphType,
  visibleLines,
  onChangeLineVisibility,
  startDate,
  endDate,
  onDateChange,
}) => {
  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      onDateChange('startDate', formattedDate);
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      onDateChange('endDate', formattedDate);
    }
  };

  return (
    <Card sx={{ padding: 4, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Control Panel
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="view-label">Select View</InputLabel>
            <Select
              labelId="view-label"
              value={view}
              onChange={(e) => onChangeView(e.target.value as ViewType)}
              label="Select View"
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="graphType-label">Select Graph Type</InputLabel>
            <Select
              labelId="graphType-label"
              value={graphType}
              onChange={(e) => onChangeGraphType(e.target.value as GraphType)}
              label="Select Graph Type"
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="line">Line</MenuItem>
              <MenuItem value="bar">Bar</MenuItem>
              <MenuItem value="doughnut">Doughnut</MenuItem>
              <MenuItem value="pie">Pie</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ marginTop: 3 }}>
        Select Data Lines
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 3 }}>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLines.credit}
                onChange={(e) => onChangeLineVisibility('credit', e.target.checked)}
              />
            }
            label="Credit"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLines.debit}
                onChange={(e) => onChangeLineVisibility('debit', e.target.checked)}
              />
            }
            label="Debit"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLines.balance}
                onChange={(e) => onChangeLineVisibility('balance', e.target.checked)}
              />
            }
            label="Balance"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLines.averageCredit}
                onChange={(e) => onChangeLineVisibility('averageCredit', e.target.checked)}
              />
            }
            label="Average Credit"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={visibleLines.averageDebit}
                onChange={(e) => onChangeLineVisibility('averageDebit', e.target.checked)}
              />
            }
            label="Average Debit"
          />
        </Grid>
      </Grid>

      <Divider sx={{ marginBottom: 3 }} />

      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Select Date Range
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate ? dayjs(startDate) : null}
              onChange={handleStartDateChange}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate ? dayjs(endDate) : null}
              onChange={handleEndDateChange}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Controls;
